.App {
  text-align: left;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: #000;
  padding: 60px 200px 200px 180px;
}

.main{
  padding: 0 20px;
  color: #000;
}

/* Mobile-friendly adjustments */
@media screen and (max-width: 768px) {
  .App-header {
    padding: 20px 20px;
    font-size: calc(8px + 2vmin);
  }

  .main {
    padding: 0 10px;
  }
}

/* Ensure proper sizing on all devices */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}